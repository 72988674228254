import { Menu } from '@element-plus/icons-vue'
export default {
    name: 'SMenus',
    props: {
        'default-active': {
            type: String,
            default: ''
        },
        'menu-items': {
            type: Array,
            default () {
                return []
            }
        },
        collapse: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {}
    },
    methods: {
        RenderIcon (icon) {
            if (!icon) return;
            return <el-icon><Menu /></el-icon>
        },
        RenderSubmenu (menu) {
            let submenu = [];
            for (let item of menu.children) {
                submenu.push(this.RenderMenu(item));
            }
            let slots = {
                title: () => <div>{this.RenderIcon(menu.meta.icon)}<span>{menu.meta.title}</span></div>
            }
            return <el-sub-menu index={menu.path}  v-slots={slots}>
                    {...submenu}
                </el-sub-menu>
        },
        RenderMenuItem (menu) {
            // menu.meta.title
            let slots = {
                title: () => <span>{menu.meta.title}</span>
            }
            return <el-menu-item index={menu.path} v-slots={slots}>
                {this.RenderIcon(menu.meta.icon)}
            </el-menu-item>
        },
        RenderMenu (menu) {
            let item = [];
            if (menu.children && menu.children.length > 0) {
                item.push(this.RenderSubmenu(menu))
            } else {
                item.push(this.RenderMenuItem(menu))
            }
            return item;
        },
    },
    render (el) {
        let _this = this;

        let props = {
            collapse: this.collapse,
            ...el.$attrs,
            'default-active': this.defaultActive
        };

        if (this.menuItems.length < 1) {
            return null
        }
        
        let menu = [];
        for (let item of this.menuItems) {
            menu = menu.concat(this.RenderMenu(item));
        }
        
        let on = {
            onOpen (...e) {
                _this.$emit('open', e)
            },
            onClose (...e) {
                _this.$emit('close', e)
            }
        }
        
        return <el-menu {...props} {...on}>{...menu}</el-menu>
    }
}